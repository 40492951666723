import React from 'react';

const Body = () => {
  return (
    <div className="gap-4 grid grid-cols-1 md:grid-cols-2 grid-rows-5 grid-flow-row w-full break-words	">
      <div className="row-span-5 flex flex-col bg-white border border-solid border-black w-full rounded-[14px] md:p-10 p-6 ">
        <p className="md:text-4xl text-2xl text-[#02015A] font-medium">
          Custom built software solutions
        </p>
        <div className="text-[#4E5774] text-lg">
          <p className="py-4">
            Improve an existing app, retain users or just delight your existing
            user base by adding in AI features that will elevate your app above
            the competition.
          </p>
          <p className="py-4">
            AI integrations are possible in almost every application.
          </p>
        </div>
      </div>

      <div className="row-span-4 flex flex-col bg-white border border-solid border-black w-full rounded-[14px] md:p-10 p-6">
        <p className="md:text-4xl text-lg text-[#02015A] font-medium">
          Experienced agile development team
        </p>
        <div className="text-[#4E5774] text-lg">
          <p className="py-4">
            Specialising in big data and AI, we maintain a sharp focus on
            demographic targeting to propel businesses forward.
          </p>
        </div>
      </div>

      <div className="row-span-5 flex flex-col bg-white border border-solid border-black w-full rounded-[14px] md:p-10 p-6">
        <p className="md:text-4xl text-2xl text-[#02015A] font-medium">
          Solutions at scale
        </p>
        <div className="text-[#4E5774] text-lg">
          <p className="py-4">
            Experienced with high volume transactions per second, AI Data
            Technology has spent its time testing solutions in high frequency
            trading & advertising server environments to ensure they are market
            fit and ahead of the competition.
          </p>
        </div>
      </div>

      <div className="row-span-4 flex flex-col bg-white border border-solid border-black w-full rounded-[14px] md:p-10 p-6">
        <p className="md:text-4xl text-2xl text-[#02015A] font-medium">
          Real-time AI <br /> Analytics for finance
        </p>
        <div className="text-[#4E5774] text-lg">
          <p className="py-4">
            AI Data Tech offers custom reports and predictive analytics for
            swift financial decisions. AI-powered financial insights provides a
            timely edge over competitors in fast paced markets.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Body;
