import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import data from './data.json';

const TermsPage = () => {
  return (
    <div>
      <Header
        topic={data.title}
        header_title={data.subTitle}
        isBottomButton={false}
      />
      <div className="bg-gradient-to-br from-[#DCE5F8_60%] to-[#DCE5F8_100%]">
        <div className="flex flex-col mb-10 pt-10 gap-[20px] justify-center items-center">
          <div className="w-1/2 max-lg:w-8/12 max-md:w-10/12">
            <p className="pb-5">{data.description}</p>
            {data.content.map((section, index) => (
              <div className="py-4" key={index}>
                <b className="text-2xl">{`${index + 1}. ${section.title}`}</b>
                <div>
                  {section.content.map((item, itemIndex) => (
                    <div className="py-1" key={itemIndex}>
                      {item.name && (
                        <b>{`${index + 1}.${itemIndex + 1}. ${item.name}: `}</b>
                      )}
                      <span dangerouslySetInnerHTML={{ __html: item.value }} />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default TermsPage;
