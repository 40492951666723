import React from 'react';
import Header from '../../components/Header';
import RowContainer from "../../components/RowContainer";
import NewsComponent from "./components/NewsComponent";
import Footer from "../../components/Footer";
import news_img_1 from "./assets/news_img_1.png";
import news_img_2 from "./assets/news_img_2.png";
import news_img_3 from "./assets/news_img_3.png";
import news_img_4 from "./assets/news_img_4.png";
import news_img_5 from "./assets/news_img_5.png";

const NewsPage = () => {
  return (
    <div>
      <Header topic="News & Insights" header_title='Check updates, latest articles and more from Aidata Technology.'
        isBottomButton={false} />
      <div
        className="flex py-[150px] md:h-[100%] md:justify-center md:items-center bg-gradient-to-br from-[#F3F7FB_40%] via-[#DCE5F8_60%] to-[#F3F7FB_100%]">
        <RowContainer
          containerStyle="md:items-center"
          isLeftImage={false}
          leftComponent={<img src={news_img_1} alt="news_img_1" className="rounded-[14px]" />}
          rightComponent={
            <NewsComponent
              title="OpenAI can spot fake images"
              topic="Artificial Technology"
              contentText="Could this be a tool we need to use every day in our lives to spot fake images, avoid being scamed and pranked? AI images and edits are now getting so good this may be something we all need to consider."
              buttonTitle="Read more"
              handleClick={() => window.open("https://www.digitaltrends.com/computing/how-to-use-openai-chatgpt-text-generation-chatbot/")}
            />
          }
        />
      </div>
      <div className="bg-gradient-to-br from-[#F3F7FB_40%] via-[#DCE5F8_60%] to-[#DCE5F8_100%]">
        <div className="flex flex-col mb-10 pt-10 gap-[50px] justify-center items-center">
          <RowContainer
            containerStyle="md:items-center"
            isLeftImage={false}
            leftComponent={<img src={news_img_2} alt="news_img_2" className="md:py-5" />}
            rightComponent={
              <NewsComponent
                title="ChatGPT can now see, hear, and speak"
                topic="Artificial Technology"
                contentText={
                  <div>In a recent development, OpenAI is launching the deployment of innovative voice and
                    image capabilities within ChatGPT.
                    <a className="underline ml-2 text-[#4E5774] font-semibold"
                      href="https://openai.com/blog/chatgpt-can-now-see-hear-and-speak"
                      target="_blank" rel="noreferrer">
                      See more
                    </a>
                  </div>
                }
                buttonTitle="Read More"
                handleClick={() => window.open("https://openai.com/blog/chatgpt-can-now-see-hear-and-speak")}
              />
            }
          />
          <RowContainer
            containerStyle="md:items-center"
            isLeftImage
            leftComponent={
              <NewsComponent
                title="DALLE 3 - Your ideas to images"
                topic="Artificial Technology"
                contentText={
                  <div>A greater level of subtlety and intricacy compared to previous systems, enabling you to
                    effortlessly transform your concepts into highly precise visuals.
                    <a className="underline ml-2 text-[#4E5774] font-semibold"
                      href="https://openai.com/dall-e-3" target="_blank" rel="noreferrer" >
                      See more
                    </a>
                  </div>
                }
                buttonTitle="Read More"
                handleClick={() => window.open("https://openai.com/dall-e-3")}
              />
            }
            rightComponent={<img src={news_img_3} alt="news_img_3" className="md:py-5" />}
          />
          <RowContainer
            containerStyle="md:items-center"
            isLeftImage={false}
            leftComponent={<img src={news_img_4} alt="news_img_4" className="md:py-5" />}
            rightComponent={
              <NewsComponent
                title="HeyGen Labs clones your voice in 7 languages"
                topic="Artificial Technology"
                contentText="Leverage a library of 300+ voices spanning over 40 languages to convert text into speech. Produce professional-grade voiceover videos with remarkable cost efficiency and reduced production time."
                buttonTitle="Try it"
                handleClick={() => window.open("https://labs.heygen.com/")}
              />
            }
          />
          <RowContainer
            containerStyle="md:items-center"
            isLeftImage
            leftComponent={
              <NewsComponent
                title="Imagination is your only limitation with MidJourney"
                topic="Artificial Technology"
                contentText="Midjourney continues to break barriers in AI image generation by prompt. It's groundbreaking AI technology that empowers users to create images by describing them in text, making complex visual generation accessible to all."
                buttonTitle="See More"
                handleClick={() => window.open("https://www.midjourney.com/showcase/recent/")}
              />
            }
            rightComponent={<img src={news_img_5} alt="news_img_5" className="md:py-5" />}
          />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default NewsPage;
