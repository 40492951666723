import React, { useRef, useState } from 'react';
import Footer from "../Footer";
import { SubmitHandler, useForm } from "react-hook-form";
import axiosClient from "../../services/axiosClient";
import axios, { AxiosError } from "axios";
import ReCAPTCHA from "react-google-recaptcha";

export type GetInTouchData = {
  name: string;
  email: string;
  company: string;
  phone: string;
  message: string;
}

const GetInTouch = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<GetInTouchData>()

  const captchaRef = useRef<ReCAPTCHA>(null);
  const [isReCAPTCHAVerified, setIsReCAPTCHAVerified] = useState(false)

  function onReCAPTCHAChange(token: string | null) {
    setIsReCAPTCHAVerified(token != null)
  }

  const onSubmit: SubmitHandler<GetInTouchData> = async (data) => {
    try {
      setIsReCAPTCHAVerified(false)
      captchaRef.current?.reset();
      const response = await axiosClient.post('/users/contact_us', {
        email: data.email,
        message: data.message,
        subject: "Company: " + data.company + " - Phone: " + data.phone,
        name: data.name,
        directly: 'AIDataTechnology',
      });

      if (response.status === 200 && response.data) {
        alert(response.data?.message);
        document.querySelector<HTMLFormElement>(".contact-form")?.reset();
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;
        alert(`Error: ${JSON.stringify(axiosError?.response?.data)}`);
      } else {
        console.log('error', err);
      }
    }
  }

  return (
    <div className="w-[100%] bg-gradient-to-br from-[#F3F7FB_10%] via-[#DCE5F8_40%] to-[#F3F7FB_100%]">
      <div className="flex flex-row md:flex md:flex-row justify-center items-center p-5 md:py-0">
        <div
          className="md:pl-[6rem] md:pt-[8rem] md:pb-[8rem] md:pr-[4rem] md:w-[1170px] md:h-[100%] rounded-2xl flex flex-col md:flex-row pl-[3rem] pt-[3rem] pb-[10%] pr-[3rem] w-[100%] bg-gradient-to-tl from-[#422C7F] to-[#AF4EDF] border border-solid border-black justify-start items-start gap-[35px]">
          <div className="md:w-[40%] h-[100%] md:justify-center md:items-center ">
            <p className="text-[25px] text-white font-bold">Get In Touch</p>
            <div className="flex justify-center items-center">
              <p className="pt-3 text-[#C4C3D5] font-normal">
                Let’s chat about the power of Ai and its potential impact on
                your business.
              </p>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-[100%] justify-start items-stretch contact-form">
            <div
              className="flex flex-col md:w-[100%] md:flex md:flex-wrap md:flex-row justify-center items-stretch md:justify-start md:items-start gap-[12px]">
              <div className="flex grow flex-col justify-center items-stretch gap-[12px]">
                <input
                  {...register('name', {
                    required: 'Required'
                  })}
                  className="w-[100%] bg-white rounded-lg px-5 py-4 border border-solid border-black placeholder:text-slate-400"
                  placeholder="Your name"
                  type="text" />
                <input
                  {...register('email', {
                    required: 'Email is required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address',
                    }
                  })}
                  className="w-[100%] bg-white rounded-lg px-5 py-4 border border-solid border-black placeholder:text-slate-400"
                  placeholder="Your Email"
                  type="text" />
                {!!errors.email && <span>{errors.email.message}</span>}
              </div>
              <div className="flex grow flex-col justify-center gap-[12px]">
                <input
                  {...register('company', {
                    required: 'Required'
                  })}
                  className="w-[100%] bg-white rounded-lg px-5 py-4 border border-solid border-black placeholder:text-slate-400"
                  placeholder="Your Company"
                  type="text" />
                <input
                  {...register('phone', {
                    required: 'Required'
                  })}
                  className="w-[100%] bg-white rounded-lg px-5 py-4  border border-solid border-black placeholder:text-slate-400"
                  placeholder="Phone"
                  type="text" />
              </div>
              <input
                {...register('message', {
                  required: 'Required'
                })}
                className="w-[100%] bg-white rounded-lg pl-5 pt-3 pb-12  border border-solid border-black placeholder:text-slate-400"
                placeholder="Message"
                type="text" />
            </div>
            <div className="pt-[20px] flex w-[100%] justify-start gap-5 items-center flex-col justify-end sm:flex-row">
              <ReCAPTCHA
                sitekey="6LfVc9QoAAAAAB1ecYBFScL9gcVREP_eb416cnvh"
                ref={captchaRef}
                onChange={onReCAPTCHAChange}
              />
              <button
                disabled={!isReCAPTCHAVerified} type="submit" className="rounded-lg w-[150px] disabled:opacity-50 border border-solid border-white text-white p-3">
                Submit Now
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GetInTouch;
