import React from 'react';
import RowContainer from '../../components/RowContainer';
import Header from '../../components/Header';
import img_1 from './components/assets/img_1.png';
import img_2 from './components/assets/img_2.png';
import img_3 from './components/assets/img_3.png';
import img_4 from './components/assets/img_4.png';
import AboutUsComponent from './components/AboutUsComponent';
import GetInTouch from '../../components/GetInTouch';

const AboutUs = () => {
  return (
    <div>
      <Header
        topic="Our Team"
        header_title="Pioneering Ai Projects Forward for a Bright Tomorrow."
        isBottomButton={false}
      />
      <div
        className="flex py-[100px] justify-center items-center bg-gradient-to-r from-[#DCE5F8] to-[#F3F7FB]">
        <div className="flex flex-col max-w-[80%] mx-auto justify-center items-center">
          <h1 className="text-center text-[#02015A] lg:text-6xl md:text-[3rem] md:leading-[44px] text-3xl font-medium">
            Our Team of Experts
          </h1>
          <h2
            className="leading-[28px] md:text-2xl text-[1.125rem] pt-[20px] tracking-widest text-[#4E5774] text-center font-normal">
            Situated in downtown Vancouver, our North American team possesses a
            unique set of skills and experience in AI technology, projects, and
            software integrations. This strategic location uniquely equips us to
            deliver outstanding client support, harnessing our teams collective
            expertise to enhance user engagement. Our mission is to harness the
            power of AI and incorporate it into products to drive user engagement,
            reduce costs, and increase revenue for our clients, offering
            innovative solutions that make a substantial impact.
          </h2>
        </div>
      </div>

      <div
        className="flex flex-col justify-center items-center bg-gradient-to-br from-[#F3F7FB_60%] via-[#DCE5F8_80%] to-[#DCE5F8_100%]">
        <div className="flex flex-col mx-auto justify-center items-center">
          <h1
            className="text-[#02015A] text-center md:text-[56px] md:leading-[64px] text-[30px] leading-[38px] font-medium pt-14">
            Our Team
          </h1>
          <RowContainer
            isLeftImage={false}
            leftComponent={
              <img src={img_1} alt="img_1" className="mx-10 max-w-[200px] rounded-[14px]"/>
            }
            rightComponent={
              <AboutUsComponent
                name="Ryan Lucas"
                job_title="Chief Executive Officer"
                contentText="Ryan has 20 years of experience in online advertising. He has founded and built businesses that have been on the forefront of online advertising.
              His knowledge of display, search and CTV advertising along with his ability to connect with and align himself with forward-thinking people helps him grow successful businesses."
              />
            }
          />
          <RowContainer
            isLeftImage={false}
            rightComponent={
              <AboutUsComponent
                name="Daniel Mckenna"
                job_title="Chief technology officer"
                contentText="Daniel leads the software and design teams. With 20 years of experience, he has the ability to bring together a team of experts and lead them in delivering exceptional software solutions.
              His skills and experience in UX experience & design, ensure each project is not only functional but visually appealing and user-friendly.
              Whether it's leading a team or designing a solution, Daniel has the experience and expertise to ensure project success."
              />
            }
            leftComponent={<img src={img_2} alt="img_2" className="mx-10 max-w-[200px] rounded-[14px]"/>}
          />
          <RowContainer
            isLeftImage={false}
            leftComponent={<img src={img_3} alt="img_3" className="mx-10 max-w-[200px] rounded-[14px]"/>}
            rightComponent={
              <AboutUsComponent
                name="Nick Nguyen"
                job_title="Senior Systems Engineer"
                contentText="In his role as a Senior Software Engineer, Nick shines as a versatile expert with substantial experience in pioneering AI integrations and their application in mobile solutions.
              His impressive track record encompasses significant contributions to large-scale software projects, consistently delivering high-quality solutions.
              Beyond his mastery of Ruby on Rails, Nick stands out as our in-house Python specialist for AI projects, ensuring he's well-prepared to achieve top-tier results in any software development endeavour."
              />
            }
          />
          <RowContainer
            isLeftImage={false}
            rightComponent={
              <AboutUsComponent
                name="Khai Nguyen"
                job_title="Senior Mobile Engineer"
                contentText="Khai is our resident Full-stack Developer an expert in automation.
              With an acute attention to detail and a comprehensive understanding of front-end and back-end technologies, he excels at creating user-friendly solutions that reflect the unique branding and identity of each project.
              Their ability to automate the development process allows for efficient and streamlined production, resulting in high-quality applications that stand out in the crowded digital marketplace."
              />
            }
            leftComponent={<img src={img_4} alt="img_4" className="mx-10 max-w-[200px] rounded-[14px]"/>}
          />
        </div>
      </div>
      <GetInTouch/>
    </div>
  );
};

export default AboutUs;
