import React, { useRef, useState } from 'react';
import Header from '../../components/Header';
import ContactCard, { ContactType } from './components/ContactCard';
import Footer from '../../components/Footer';
import { SubmitHandler, useForm } from "react-hook-form";
import axiosClient from "../../services/axiosClient";
import axios, { AxiosError } from "axios";
import { GetInTouchData } from "../../components/GetInTouch";
import ReCAPTCHA from "react-google-recaptcha";

const ContactPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<GetInTouchData>()

  const captchaRef = useRef<ReCAPTCHA>(null);
  const [isReCAPTCHAVerified, setIsReCAPTCHAVerified] = useState(false)

  function onReCAPTCHAChange(token: string | null) {
    setIsReCAPTCHAVerified(token != null)
  }

  const onSubmit: SubmitHandler<GetInTouchData> = async (data) => {
    try {
      setIsReCAPTCHAVerified(false)
      captchaRef.current?.reset();

      const response = await axiosClient.post('/users/contact_us', {
        email: data.email,
        message: data.message,
        subject: "Company: " + data.company + " - Phone: " + data.phone,
        name: data.name,
        directly: 'AIDataTechnology',
      });

      if (response.status === 200 && response.data) {
        alert(response.data?.message);
        document.querySelector<HTMLFormElement>(".contact-form")?.reset();
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;
        alert(`Error: ${JSON.stringify(axiosError?.response?.data)}`);
      } else {
        console.log('error', err);
      }
    }
  }

  return (
    <div>
      <Header
        topic="Contact Us"
        header_title="Have a question, suggestion, or just want to learn more?"
        isBottomButton={false}
      />
      <div
        className="py-16 w-full bg-gradient-to-br from-[#F3F7FB_40%] via-[#DCE5F8_60%] to-[#F3F7FB_100%] justify-center items-center">
        <div className="max-w-6xl px-6 w-full mx-auto">
          <div className="mx-auto grid grid-cols-1 sm:grid-cols-3 gap-8">
            <ContactCard
              contactType={ContactType.EMAIL}
              message="Have a project in mind? Send a message."
              contactMethods="contact@aidatatechnology.com"
            />
            <ContactCard
              contactType={ContactType.SOCIAL}
              message="Would you like to join our growing team?"
              contactMethods="contact@aidatatechnology.com"
            />
            <ContactCard
              contactType={ContactType.PHONE}
              message="We’re interested in working together!"
              contactMethods="1 (888) 401 3862"
            />
          </div>
        </div>
      </div>
      <div className="w-full bg-gradient-to-bl from-[#F3F7FB_50%] via-[#DCE5F8_80%] to-[#DCE5F8_100%]">
        <div className="max-w-6xl px-6 w-full mx-auto">
          <div className="flex flex-row justify-center items-center">
            <div
              className="flex flex-col justify-center items-center w-full rounded-2xl gap-[35px] py-16">
              <p
                className="md:text-[56px] text-[30px] leading-[38px] text-center text-[#02015A] font-medium md:leading-[64px]">
                Get In Touch
              </p>
              <form onSubmit={handleSubmit(onSubmit)} className="w-4/5 flex flex-col justify-start items-stretch contact-form">
                <div
                  className="flex flex-col md:w-[100%] md:flex md:flex-wrap md:flex-row justify-center items-stretch md:justify-start md:items-start gap-[12px]">
                  <div className="flex grow flex-col justify-center items-stretch gap-[12px]">
                    <input
                      {...register('name', {
                        required: 'Required'
                      })}
                      className="w-[100%] bg-white rounded-lg px-5 py-4 border border-solid border-black placeholder:text-slate-400"
                      placeholder="Your name"
                      type="text"
                    />
                    <input
                      {...register('email', {
                        required: 'Email is required',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'Invalid email address',
                        }
                      })}
                      className="w-[100%] bg-white rounded-lg px-5 py-4 border border-solid border-black placeholder:text-slate-400"
                      placeholder="Your Email"
                      type="text"
                    />
                    {!!errors.email && <span>{errors.email.message}</span>}
                  </div>
                  <div className="flex grow flex-col justify-center items-center gap-[12px]">
                    <input
                      {...register('company', {
                        required: 'Required'
                      })}
                      className="w-[100%] bg-white rounded-lg px-5 py-4 border border-solid border-black placeholder:text-slate-400"
                      placeholder="Your Company"
                      type="text"
                    />
                    <input
                      {...register('phone', {
                        required: 'Required'
                      })}
                      className="w-[100%] bg-white rounded-lg px-5 py-4  border border-solid border-black placeholder:text-slate-400"
                      placeholder="Phone"
                      type="text"
                    />
                  </div>
                  <input
                    {...register('message', {
                      required: 'Required'
                    })}
                    className="w-[100%] bg-white rounded-lg pl-5 pt-3 pb-12  border border-solid border-black placeholder:text-slate-400"
                    placeholder="Message"
                    type="text"
                  />
                </div>
                <div className="pt-[20px] flex w-[100%] justify-start gap-5 items-center flex-col justify-end sm:flex-row">
                  <ReCAPTCHA
                    sitekey="6LfVc9QoAAAAAB1ecYBFScL9gcVREP_eb416cnvh"
                    ref={captchaRef}
                    onChange={onReCAPTCHAChange}
                  />
                  <button
                    disabled={!isReCAPTCHAVerified}
                    type="submit"
                    className="rounded-lg w-[150px] bg-gradient-to-br from-[#AF4EDF_-12.75%] to-[#7547CD_113.04%] disabled:opacity-50 text-white p-3 ">
                    Submit Now
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ContactPage;
