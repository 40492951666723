import React from 'react';
import img1 from '../../../../../../assets/img1.svg';
import img2 from '../../../../../../assets/img2.svg';
import img3 from '../../../../../../assets/img3.svg';

const SubBody = () => {
  return (
    <div className="max-w-6xl px-6 w-full mx-auto">
      <div className="flex lg:flex-row flex-col justify-center items-center gap-16 my-8">
        <img src={img1} alt="image1" />
        <div className="flex flex-col">
          <p className="md:text-4xl text-2xl text-[#02015A] font-medium">
            Custom reports inform decisions
          </p>
          <div className="text-[#4E5774] text-lg">
            <p className="py-4">
              AI Data Technologies lead the charge in the next generation of AI
              innovation, as pioneers in B2B data excellence. Specialising in
              big data and AI, we maintain a sharp focus on demographic
              targeting to propel businesses forward.
              <br />
              <br />
              Leverage new AI data insights for new revenue streams, with
              identified trends on optimized operations, potential new and
              innovate products or services, expanding their customer base and
              revenue.
            </p>
          </div>
        </div>
      </div>
      <div className="flex lg:flex-row-reverse flex-col justify-center items-center gap-16 my-8">
        <img src={img2} alt="image2" />
        <div className="flex flex-col">
          <p className="md:text-4xl text-2xl text-[#02015A] font-medium">
            Increase efficiency while reducing carbon output
          </p>
          <div className="text-[#4E5774] text-lg">
            <p className="py-4">
              AI offers a transformative solutions to mitigate the carbon
              footprint. Optimize transactions per second (TPS) through dynamic
              resource allocation, predictive maintenance, and workload
              optimization.
              <br /> <br />
              Predict when energy-efficient cooling and load balancing should be
              ramped up and down, reducing overall consumption. AI Data Tech
              helps minimizes network traffic, optimizing data transmission, and
              integrates green energy sources to further cut emissions. Reduce
              your TPS requirements and save on energy, hardware and even human
              capital.
            </p>
          </div>
        </div>
      </div>
      <div className="flex lg:flex-row flex-col justify-center items-center gap-16 my-8">
        <img src={img3} alt="image3" />
        <div className="flex flex-col">
          <p className="md:text-4xl text-2xl text-[#02015A] font-medium">
            QPS Throttling
          </p>
          <div className="text-[#4E5774] text-lg">
            <p className="py-4">
              Within the CTV advertising industry, a recent initiative is
              underway to minimize server load requests and enhance overall
              operational efficiency. Clients consistently face demands to
              augment RPM while concurrently reducing requests. In response,
              industry leaders are actively implementing robust throttling
              measures to manage high request volumes and ensure optimal server
              cost efficiency.
              <br /> <br />
              Clients are confronted with the imperative to reduce requests
              while maintaining a robust advertising fill, thereby sustaining or
              elevating their revenue streams. The QPS Throttling service
              leverages advanced AI algorithms to selectively diminish requests,
              prioritizing those most likely to result in successful fills and
              simultaneously attaining higher RPM price points.{' '}
              <b>
                Clients utilizing the service witness an immediate, substantial
                increase in revenue, with double-digit percentage gains upon the
                implementation of this transformative technology.
              </b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SubBody;
