import React from 'react';
import Header from '../../components/Header';
import Footer from "../../components/Footer";
import data from './data.json';

const PrivacyPage = () => {

  return (
    <div>
      <Header topic={data.title} header_title={data.subTitle}
        isBottomButton={false} />
      <div className="bg-gradient-to-br from-[#DCE5F8_60%] to-[#DCE5F8_100%]">
        <div className="flex flex-col mb-10 pt-10 gap-[20px] justify-center items-center">
          <div className="w-1/2 max-lg:w-8/12 max-md:w-10/12">
            <p className='pb-5'>
              {data.description}
            </p>
            {
              data.content.map((section, index) => <div className='py-3' key={index} >
                <b className="text-2xl">{section.title}</b>
                <div>
                  {
                    section.content?.map((row, index) => <div className='py-2' key={index}>
                      <b className="text-xl">{row.title}</b>

                      {
                        row.data.map((parent, parentIndex) => {
                          let rowComponent = null;
                          switch (parent.type) {
                            case "BULLET": {
                              rowComponent = <ul className='list-disc pl-8 pb-3'> {
                                  parent.content.map((subItem, subIndex) => {
                                    const textArr = subItem.split(":")
                                    return <li key={subIndex}>
                                      <b>{`${textArr[0]}: `}</b> {`${textArr[1]}`}
                                    </li>
                                  })
                                } </ul>
                              break;
                            }

                            case "BULLET_TEXT": {
                              rowComponent = <ul className='list-disc pl-8 pb-3'>{parent.content.map((subItem, subIndex) => <li key={subIndex}>{subItem}</li>)}</ul>
                              break;
                            }

                            default: {
                              rowComponent = parent.content.map((subItem, subIndex) => <p className='py-1' key={subIndex}>{subItem}</p>)
                              break;
                            }
                          }

                          return <div className='pt-2' key={parentIndex}>{rowComponent}</div>
                        })
                      }
                    </div>
                    )
                  }
                </div>
              </div>)
            }
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default PrivacyPage;
