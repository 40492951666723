import logo from "../../assets/logo.svg";
import React, { useState } from "react";
import { FaBars, FaTimes } from 'react-icons/fa';
import { MdArrowOutward } from "react-icons/md";
import { Button } from "../Button";

import { Link } from "react-router-dom";
import { PATHS } from "../../constants/paths";

const Navbar = () => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const handleNav = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  return (
    <>
      <div className="px-6 mr-auto w-full">
        <div
          className='relative z-10 flex bg-[#ffffff]/[0.55] backdrop-blur-[12.5px] justify-between items-center max-w-[1170px] h-[70px] mx-auto text-white rounded-[14px]'>
          <Link to={PATHS.HOME}><img src={logo} alt="logo" className="pl-6" /></Link>
          <ul className='hidden md:flex items-center'>
            <li className='px-7 text-[#02015A]'><Link to={PATHS.NEWS}>Ai Industry News</Link></li>
            <li className='px-7 text-[#02015A]'><Link to={PATHS.ABOUT}>Team</Link></li>
            <li className='pl-4 text-[#02015A]'>
              <Link to={PATHS.CONTACT}>
                <Button
                  className="justify-center items-center rounded-r-[14px] flex-row flex h-[70px] px-6 text-white  bg-gradient-to-br from-[#AF4EDF_-12.75%] to-[#7547CD_113.04%]"
                  text="Get Started"
                  rightIcon={
                    <MdArrowOutward size={15} className="ml-2.5" />
                  }
                />
              </Link>
            </li>
          </ul>
          <div onClick={handleNav} className='block md:hidden'>
            {!isMenuOpened && <FaBars className={'text-[#7547CD] mr-6'} size={22} />}
          </div>
        </div>
      </div>
      <div className={isMenuOpened
        ? 'fixed z-10 left-0 top-0 w-full h-screen flex flex-col justify-center px-6 bg-[#422C7F] bg-gradient-to-br from-[#AF4EDF_-12.75%] to-[#7547CD_113.04%] ease-out duration-500'
        : 'fixed z-10 left-0 top-[-100vh] w-full h-screen  flex flex-col justify-center px-6 bg-[#422C7F] bg-gradient-to-br from-[#AF4EDF_-12.75%] to-[#7547CD_113.04%] ease-in-out duration-500'
      }>
        {isMenuOpened &&
          <div
            onClick={handleNav}
            className='flex justify-end fixed top-[10vh] right-[10vw]'>
            <FaTimes className={'text-[#FFF]'} size={20} />
          </div>
        }
        <ul className='text-white font-medium text-2xl'>
          <li className='px-4 py-6'><Link to={PATHS.HOME}>Home</Link></li>
          <li className='px-4 py-6'><Link to={PATHS.NEWS}>Ai Industry News</Link></li>
          <li className='px-4 py-6'><Link to={PATHS.ABOUT}>Team</Link></li>
          <div className="px-4 py-16">
            <Link to={PATHS.CONTACT}>
              <Button
                className="w-[200px] justify-center py-4 rounded-lg flex flex-row items-center bg-white text-[#4E5774] font-semibold text-xl"
                text="Get Started"
                rightIcon={
                  <MdArrowOutward size={18} className="ml-2.5" />
                }
              />
            </Link>
          </div>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
