import React from 'react';
import logo from '../../assets/logo.svg';
import { Button } from '../Button';
import { MdArrowOutward } from 'react-icons/md';

import { Link } from "react-router-dom";
import { PATHS } from "../../constants/paths";

const Footer = () => {
  return (
    <div className="w-full">
      <div className="max-w-6xl px-6 w-full mx-auto">
        <div className="relative z-10 flex  md:justify-between justify-center items-center max-w-[1170px] text-white py-[48px] rounded-[14px]">
          <Link to={PATHS.HOME}><img src={logo} alt="logo" className="md:pl-6" /></Link>
          <ul className="hidden md:flex items-center">
            <li className="px-7 text-[#02015A]"><Link to={PATHS.HOME}>Home</Link></li>
            <li className="px-7 text-[#02015A]"><Link to={PATHS.ABOUT}>About us</Link></li>
            <li className="pl-4 text-[#02015A]">
              <Link to={PATHS.CONTACT}>
                <Button
                  className="justify-center items-center rounded-[3px] flex-row flex h-[45px] px-6 text-white  bg-gradient-to-br from-[#AF4EDF_-12.75%] to-[#7547CD_113.04%]"
                  text="Get Started"
                  rightIcon={<MdArrowOutward size={18} className="ml-2.5" />}
                />
              </Link>
            </li>
          </ul>
        </div>
        <hr className="divide-solid divide-neutral-900" />
        <div className="flex-col md:flex-row relative z-10 flex justify-between items-center h-[100%] py-5  text-white opacity-75 rounded-[14px]">
          <p className="md:px-7 font-light text-[#4E5774] text-center">
            © Aidata Technology. All Rights Reserved.
          </p>
          <ul className="flex flex-col md:flex-row pt-0 max-sm:py-5 justify-center items-center text-center">
            <li className="md:px-7 font-light text-[#4E5774]">
              <Link to={PATHS.PRIVACY}>Privacy Policy</Link>
            </li>
            <li className="md:px-7 font-light text-[#4E5774]">
              <Link to={PATHS.TERMS}>Terms & Conditions</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
