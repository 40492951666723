import React from 'react';
import GetStarted from "./components/GetStarted";
import InfoSection from "./components/InfoSection";
import Analytics from "./components/Analytics";
import GetInTouch from "../../components/GetInTouch";

const HomePage = () => {
  return (
    <div>
      <GetStarted/>
      <InfoSection/>
      <Analytics/>
      <GetInTouch/>
    </div>
  )
};

export default HomePage;
