import React, { ButtonHTMLAttributes, ReactNode } from "react";

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  text: string;
  rightIcon: ReactNode;
};

export const Button: React.FC<Props> = (props) => {
  const { text, rightIcon, className } = props;
  return (
    <button
      type='button'
      className={className}
      {...props}
    >
      {text}
      {rightIcon && <span className='justify-center items-center'>{rightIcon}</span>}
    </button>
  )
}
