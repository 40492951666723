import React from 'react';
import SubBody from "./components/SubBody";

const Analytics = () => {
  return (
    <div className="py-16 w-full bg-gradient-to-br from-[#FFFFFF_0%] via-[#F7F9FC_60%] to-[#DCE5F8_100%]">
      <SubBody/>
    </div>
  )
}

export default Analytics;
