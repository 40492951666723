import './styles.css';
import React from 'react';
import { Button } from '../Button';
import { MdArrowOutward } from 'react-icons/md';
import NavBar from "../NavBar"


const Header = ({ topic, header_title, isBottomButton }: {
  topic: string;
  header_title: string,
  isBottomButton: boolean
}) => {
  return (
    <div className="sectionContainer1">
      <NavBar/>
      <div className="max-w-6xl w-full h-screen mx-auto text-center px-6 flex flex-col justify-center">
        <h1 className="text-center text-[#02015A] lg:text-[4.125rem] md:text-5xl text-4xl font-medium md:py-6">
          {topic}
        </h1>
        <h2 className="md:text-2xl text-[1.125rem] py-4 text-[#4E5774] text-center font-normal">
          {header_title}
        </h2>
        <div className={`${isBottomButton ? 'flex' : 'hidden'}`}>
          <Button
            className="relative z-[2] w-[200px] rounded-md my-6 mx-auto py-4 px-9 font-semibold flex flex-row justify-center items-center text-white bg-gradient-to-br from-[#AF4EDF_-12.75%] to-[#7547CD_113.04%]"
            text="Get Started"
            rightIcon={
              <MdArrowOutward size={18} className="ml-2.5"/>
            }
          />
        </div>
      </div>
    </div>
  );
};
export default Header;
