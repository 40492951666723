import React from 'react';
import './App.css';
import { HashRouter, Route, Routes, useLocation } from "react-router-dom";
import { PATHS } from "./constants/paths";
import HomePage from "./scenes/HomePage";
import NewsPage from "./scenes/NewsPage";
import ContactPage from "./scenes/ContactPage";
import AboutUs from './scenes/AboutUsPage';
import TermsPage from './scenes/TermsPage';
import PrivacyPage from './scenes/PrivacyPage';
import { useEffect } from "react";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <HashRouter>
      <ScrollToTop />
      <Routes>
        <Route path={PATHS.HOME} element={<HomePage />} />
        <Route path={PATHS.NEWS} element={<NewsPage />} />
        <Route path={PATHS.CONTACT} element={<ContactPage />} />
        <Route path={PATHS.ABOUT} element={<AboutUs />} />
        <Route path={PATHS.TERMS} element={<TermsPage />} />
        <Route path={PATHS.PRIVACY} element={<PrivacyPage />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
