import React, { ReactNode } from 'react';
// import icon from '../assets/icon.svg'

type Props = {
  name: string;
  job_title: string;
  contentText: ReactNode | string;
};

const AboutUsComponent: React.FC<Props> = (props) => {
  const { name, job_title, contentText } = props;
  return (
    <div className="flex flex-col justify-center items-start gap-[20px]">
      <p className="text-[#02015A] text-[32px] font-semibold leading-[32px] max-w-[525px] ">
        {name}
      </p>
      <p className="text-[#AF4EDF] uppercase tracking-[3px] text-[15px] font-bold leading-[15px]">
        {job_title}
      </p>
      <div className="pt-[20px] text-[#4E5774] text-[16px] font-normal leading-[26px] max-w-[560px]">
        {contentText}
      </div>
      {/* <img src={icon} alt='icon_1' className="p-2  bg-gradient-to-br from-[#AF4EDF_-12.75%] to-[#7547CD_113.04%]"/> */}
    </div>
  );
};

export default AboutUsComponent;
