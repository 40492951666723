import React from 'react';
import Body from "./components/Body";

const InfoSection = () => {
  return (
    <div className='py-16 w-full bg-gradient-to-br from-[#F3F7FB_40%] via-[#DCE5F8_60%] to-[#F3F7FB_100%]'>
      <div className="max-w-6xl px-6 w-full mx-auto">
        <h1
          className="text-center text-[#02015A] lg:text-6xl md:text-[3rem] md:leading-[44px] text-3xl font-medium">
          A fast paced technology team at the forefront of AI
        </h1>
        <h2
          className="md:text-2xl text-[1.125rem] py-4 text-[#4E5774] text-center font-normal">
          We leverage our rich history in developing technology for all platforms
          and adding AI to bring its unique advantages.
        </h2>
        <Body />
      </div>
    </div>
  )
};

export default InfoSection;
