import './styles.css';
import React from 'react';
import { Button } from '../../../../components/Button';
import { MdArrowOutward } from 'react-icons/md';
import NavBar from '../../../../components/NavBar';
import { Link } from 'react-router-dom';
import { PATHS } from '../../../../constants/paths';

const GetStarted = () => {
  return (
    <div className="sectionContainer">
      <NavBar />
      <div className="max-w-6xl w-full sm:h-screen mx-auto text-center py-10 sm:py-0 px-6 flex flex-col justify-center">
        <h1 className="text-center text-[#02015A] lg:text-[4.125rem] md:text-5xl text-4xl font-medium md:py-12">
          Industry leaders in AI innovation.
        </h1>
        <h2 className="md:text-2xl text-[1.125rem] py-8 text-[#4E5774] text-center font-normal">
          AI Data Technologies lead the charge in the next generation of AI
          innovation, as pioneers in B2B data excellence. Specialising in big
          data and AI, we maintain a sharp focus on staying at the forefront of
          bleeding edge AI development in order to propel businesses forward.
        </h2>
        <Link to={PATHS.CONTACT}>
          <Button
            className="relative z-[2] w-[200px] rounded-md my-6 mx-auto py-4 px-9 font-semibold flex flex-row justify-center items-center text-white bg-gradient-to-br from-[#AF4EDF_-12.75%] to-[#7547CD_113.04%]"
            text="Get Started"
            rightIcon={<MdArrowOutward size={18} className="ml-2.5" />}
          />
        </Link>
      </div>
    </div>
  );
};
export default GetStarted;
