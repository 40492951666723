import React from 'react';
import email from '../assets/email.svg';
import social from '../assets/social.svg';
import phone from '../assets/phone.svg';

export enum ContactType {
  EMAIL,
  SOCIAL,
  PHONE
}

type Props = {
  contactType: ContactType;
  contactMethods: string;
  message: string;
};

const getContactIcon = (type: ContactType) => {
  switch (type) {
    case ContactType.EMAIL:
      return <img src={email} alt="email_icon"/>;
    case ContactType.SOCIAL:
      return <img src={social} alt="social_icon"/>;
    case ContactType.PHONE:
      return <img src={phone} alt="phone_icon"/>;
  }
};

const ContactCard: React.FC<Props> = (props) => {
  const { contactType, contactMethods, message } = props;
  return (
    <div
      className="flex flex-col w-full rounded-[14px] border-[0.75px] border-solid border-[#BBB] bg-white justify-center items-center py-10 px-5 my-6 gap-10">
      {getContactIcon(contactType)}
      <p className="w-full text-center text-[#485159] text-[16px] font-medium leading-[24px] break-words">
        {message}
      </p>
      <p className="w-full text-[#191C1F] text-center text-[22px] font-semibold leading-[26.4px] break-words">
        {contactMethods}
      </p>
    </div>
  );
};
export default ContactCard;
