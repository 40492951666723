import React, { ReactNode } from 'react';

type Props = {
  leftComponent: ReactNode;
  rightComponent: ReactNode;
  isLeftImage: boolean;
  containerStyle?: string;
};

const RowContainer: React.FC<Props> = (props) => {
  const {
    leftComponent,
    rightComponent,
    isLeftImage,
    containerStyle,
  } = props;

  return (
    <div
      className={`flex flex-1 px-[35px] justify-center items-center gap-10 my-[50px] md:flex md:flex-row md:justify-center md:items-start ${
        isLeftImage ? 'flex-col-reverse' : 'flex-col'
      } ${containerStyle} `}>
      {leftComponent}
      {rightComponent}
    </div>
  );
};

export default RowContainer;
