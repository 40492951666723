import React, { ReactNode } from 'react';
import { Button } from '../../../../components/Button';
import { MdArrowOutward } from "react-icons/md";

type Props = {
  topic: string;
  title: string;
  buttonTitle: string;
  handleClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  contentText: ReactNode | string;
};

const NewsComponent: React.FC<Props> = (props) => {
  const { topic, title, contentText, buttonTitle, handleClick } = props;
  return (
    <div className="flex flex-col justify-center items-start gap-3">
      <p className="text-[#AF4EDF] uppercase tracking-[2.6px] text-[13px] font-bold leading-[13px]">
        {topic}
      </p>
      <p className="text-[#02015A] text-[32px] font-semibold leading-[40px] max-w-[525px] ">
        {title}
      </p>
      <div className="text-[#4E5774] text-[16px] font-normal leading-[24.8px] max-w-[560px]">
        {contentText}
      </div>
      <Button
        onClick={(e) => handleClick && handleClick(e)}
        className="mt-[40px] text-[14px] justify-center font-semibold items-center rounded-[3px] flex-row flex h-[45px] px-[25px] text-white  bg-gradient-to-r from-[#AF4EDF_-12.75%] to-[#7547CD_113.04%]"
        text={buttonTitle}
        rightIcon={
          <MdArrowOutward size={15} className="ml-2" />
        }
      />
    </div>
  );
};

export default NewsComponent;
