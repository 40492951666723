export const PATHS = {
  HOME: "/",
  NEWS: "/news",
  CONTACT: "/contact",
  ABOUT: "/about",
  PRIVACY: "/privacy",
  TERMS: "/terms",
};

export const NAV_PATHS = Object.values(PATHS).filter((p) => p !== PATHS.HOME);
